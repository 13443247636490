<template>
  <v-container fluid fill-height>
    <v-card flat height="auto" width="400px" style="margin: 0 auto 0 auto">
      <v-card>
        <v-row class="d-flex justify-center pt-5 pb-5">
          <h4 style="color: red;">{{ getError }}</h4>
          <h4 style="">{{ getSuccess === false ? "" : getSuccess }}</h4>
        </v-row>
        <div class="pb-5" style="text-align: center" id="register-info">
          <a href="https://challenger.edisoninteractive.com/">{{ getSuccess === false ? "" : getSuccess.length > 0 ? "Login to continue" : null }}</a>
        </div>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  created() {
    console.log("Test", this.$route.query.user);
    this._register({uuid: this.$route.query.user})
  },
    methods: {
    ...mapActions({
      _register: "auth/verify",
    }),
    ...mapMutations({
      setError: "auth/setError",
      setSuccess: "auth/setSuccess",
    }),  
  },
  computed: {
    ...mapGetters({
      getError: "auth/getError",
      getSuccess: "auth/getSuccess",
    })
  },
};
</script>

