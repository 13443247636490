<template>
  <v-container fluid fill-height>
    <v-card flat height="auto" width="400px" style="margin: 0 auto 0 auto">
      <v-card>
        <div class="pt-5 pb-5" style="text-align: center" id="register-info">
          Enter your information below to create an account.
        </div>
        <div class="pb-5" style="text-align: center" id="register-info">
          <v-form v-model="isFormVaild">
            <v-text-field
              filled
              hide-details
              prepend-inner-icon="mdi-account"
              id="register-first-name"
              label="First Name"
              v-model="first_name"
              style="margin: 10px; margin-top: 0 !important; padding: 0px"
            />
            <v-text-field
              filled
              hide-details
              prepend-inner-icon="mdi-account"
              id="register-last-name"
              label="Last Name"
              v-model="last_name"
              style="margin: 10px; margin-top: 0 !important; padding: 0px"
            />
            <v-text-field
              filled
              hide-details
              prepend-inner-icon="mdi-email"
              id="create-account-email"
              label="Email"
              :rules="emailRules"
              v-model="email"
              style="margin: 10px; margin-top: 0 !important; padding: 0px"
            />
            <v-text-field
              filled
              hide-details
              id="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              prepend-inner-icon="mdi-lock"
              label="Password"
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              style="margin: 10px; padding: 0px"
            />
            <div style="margin-left: 10px; margin-right: 10px">
              <v-select
                v-model="select"
                :hint="` ${select.location}`"
                :items="items"
                item-text="team"
                item-value="location"
                label="Select Your Location"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </div>
          </v-form>
        </div>
      </v-card>
      <v-row>
        <v-col align="center" class="pt-7">
          <v-btn
            large
            color="primary"
            id="create-account-button"
            style="width: 100%"
            @click="logIn"
            >Create Account</v-btn
          >
        </v-col>
      </v-row>
       <v-row class="d-flex justify-center pt-5 pb-5">
          <h4 style="color: red;">{{ getError }}</h4>
          <h4 style="">{{ getSuccess === false ? "" : getSuccess }}</h4>
        </v-row>
    </v-card>
  </v-container>
</template>

<script>
import vSelect from "vselect-component";
import Vue from "vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
Vue.use(vSelect);
export default {
  data() {
    return {
      select: { team: "", location: "" },
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      showPassword: false,
      showLoading: false,
      isFormVaild: false,
      emailRules: [(v) => /.+@.+/.test(v) || "Invalid Email address"],
      items: [
        { team: "ATL", location: "Atlanta, GA" },
        { team: "AUS", location: "Austin, TX" },
        { team: "BNA", location: "Nashville, TN" },
        { team: "BOS", location: "Boston, MA" },
        { team: "CAC", location: "Canada Central" },
        { team: "CAE", location: "Canda East" },
        { team: "CAW", location: "Candaa West" },
        { team: "CLT", location: "Charlotte, NC" },
        { team: "CMH", location: "Columbus, OH" },
        { team: "DCA", location: "Arlington, VA" },
        { team: "DEN", location: "Denver , CO" },
        { team: "DFW", location: "Dallas–Fort Worth, TX" },
        { team: "DTW", location: "Detriot. MI" },
        { team: "EWR", location: "Newark, NJ" },
        { team: "FLL", location: "Fort Lauderdale, FL" },
        { team: "HNL", location: "Honolulu, HI" },
        { team: "HOU", location: "Houston, TX" },
        { team: "IAD", location: "Dulles, VA" },
        { team: "IAH", location: "Houston, TX" },
        { team: "IND", location: "Indianapolis, IN" },
        { team: "ITO", location: "Hilo, HI" },
        { team: "JFK", location: "Queens, NY" },
        { team: "KOA", location: "Kona, HI" },
        { team: "LAS", location: "Las Vegas, NV" },
        { team: "LAX", location: "Los Angeles, CA" },
        { team: "LGA", location: "Queens, NY" },
        { team: "LIH", location: "Lihue, HI" },
        { team: "MCO", location: "Orlando, FL" },
        { team: "MDW", location: "Chicago, IL" },
        { team: "MIA", location: "Miami, FL" },
        { team: "MSP", location: "Minneapolis−Saint Paul, MN" },
        { team: "NYM", location: "Manhatten " },
        { team: "OGG", location: "Kahului" },
        { team: "ONT", location: "Ontario, CA" },
        { team: "ORD", location: "Chicago, IL" },
        { team: "PDX", location: "Portland, OR" },
        { team: "PHX", location: "Phoenix, AZ" },
        { team: "PSP", location: "Palm Springs, CA" },
        { team: "RSW", location: "Fort Myers, FL" },
        { team: "SAN", location: "San Diego, CA " },
        { team: "SAT", location: "San Antonio, TX" },
        { team: "SEA", location: "Seattle, WA" },
        { team: "SFO", location: "San Francisco, Ca" },
        { team: "SJC", location: "San Jose, CA" },
        { team: "SLC", location: "Salt Lake, UT" },
        { team: "SMF", location: "Sacramento, CA" },
        { team: "SNA", location: "Santa Ana, CA" },
        { team: "TUS", location: "Tucson, AZ" },
      ],
    };
  },
  methods: {
    ...mapActions({
      _register: "auth/register",
    }),
    ...mapMutations({
      setError: "auth/setError",
      setSuccess: "auth/setSuccess",
    }),
    logIn() {
      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        team: this.select.team,
        location: this.select.location,
        email: this.email,
        password: this.password,
      };
      this._register(data);
    },
  },
  computed: {
    ...mapGetters({
      getError: "auth/getError",
      getSuccess: "auth/getSuccess",
    })
  },
};
</script>

<style>
select {
  border: solid 1px #010203;
  border-radius: 3px;
  width: 100px;
  height: 25px;
  color: black;
  line-height: normal;
  overflow: visible;
  text-align: center;
}
</style>
