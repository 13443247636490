<template>
  <login-main />
</template>

<script>
import LoginMain from "@/components/login/LoginMain.vue";

export default {
  components: {
    LoginMain,
  },
  mounted() {}
};
</script>
